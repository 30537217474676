import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Admin from './pages/Admin';
import TableOrder from './pages/TableOrder';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import { Dashboard } from './pages/Dashboard';
import axios from 'axios';

const App = () => {
  const token = localStorage.getItem('token');

  // async function auth(token){
  //   const response =await axios.post("http://localhost:3005/dashboard",{token});}

  // if(token){auth(token);}

  return(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login" element={<Admin />} />
      <Route path="reserve" element={<TableOrder/>} />
      <Route path="dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}/>
    </Routes>
  </BrowserRouter>
  )
};

export default App;
