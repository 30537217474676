import React from 'react';
import { AdminPanel } from '../components/AdminPanel/AdminPanel';

export const Dashboard = () => {
  return (<>
    <AdminPanel/>
    </>
  )
}

