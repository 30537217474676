import axios from 'axios';
import React, { useState ,useEffect} from 'react';
import './adminpanel.css';
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import * as shamsi from 'shamsi-date-converter';

export default function ShowReserves() {

  var date = new DateObject({ calendar: persian, locale: persian_fa });
  const [record,setRecord] =  useState([]);
  const [reserved,setReserved] = useState([]);
  useEffect(() => { 
    try{
      const token = localStorage.getItem('token');
      axios.post("http://localhost:3005/dashboard/reserves",{token}).then((response)=>setRecord(response.data.reverse()));
    }
      catch(err){
        console.log(err);
      }
  },[]);

  const handleReserve= async (id)=>{
    try{
      const token = localStorage.getItem('token');
       await axios.post(`http://localhost:3005/dashboard/availibilitystatus/${id}`,{token});
    }
    catch(err){
      console.log(err);
    }
  }

return (
    <table>
      <tbody>
    <tr>
      <th>ردیف</th>
      <th>نام</th>
      <th>تلفن</th>
      <th>شماره میز</th>
      <th>مکان</th>
      <th>تاریخ</th>
      <th>ساعت</th>
      <th>وضعیت رزرو</th>
    </tr>
     {record.map((p,index)=>{
      var dateonly = p.reservation.date.substring(0,10).replace(/-/g,'/');
      var timeonly = new Date(p.reservation.date).getHours();
      return(
      <tr key={index}>
       <td>{index+1}</td>
      <td>{p.reservation.name}</td>
       <td>{p.reservation.phone}</td>
       <td>{p.reservation.tablenumber}</td> 
      <td>{p.reservation.location}</td>
       <td>{shamsi.gregorianToJalali(dateonly).join('/')}</td>
       <td>{timeonly}</td> 
      <td>
    {p.isAvailable?
    <div onClick={()=>handleReserve(p._id)} className='orderstatus cancel'>سفارش لغو شده</div>
    :<div onClick={()=>handleReserve(p._id)} className='orderstatus'>سفارش تایید شده</div>}  
        </td>
      </tr>
      )
      })} 
      </tbody>
  </table>
  )
}
