import React, { useState } from 'react';
import './Login.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

const Login = () => {
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [navigate, setNavigate]= useState(false);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try{
    const response = await axios.post('http://localhost:3005/user/login',{
      email,password
    });
    const token  =  response.data.token;
    console.log(token);
    localStorage.setItem("token", token);
    setNavigate(true);
  }
  catch(error){
    return alert("نام کاربری یا رمز عبور اشتباه است")
  }
  }

  if(navigate){
    return <Navigate to="/dashboard"/>
  }
  return (
    <>
      <div className="container">
        <div className="title">ورود به پنل مدیریت</div>
        <Form onSubmit={handleSubmit} className="p-5">
          <Form.Group size='lg' controlId='email' className="m-4">
          <Form.Label>ایمیل</Form.Label>
        <Form.Control
            autoFocus
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group >
        <Form.Group size='lg' controlId='password' className="m-4">
          <Form.Label>رمز عبور</Form.Label>
          <Form.Control
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
         </Form.Group>
            <Button block="true" size="lg" type="submit" className="m-4 pe-auto" disabled={!validateForm()}>
          ورود
            </Button>
        </Form>
      </div>
    </>
  );
};

export default Login;
