import React from 'react';
import ReserveTable from '../components/TableOrder/ReserveTable';
import { Footer } from '../container';
import { Navbar } from '../components';
import { useState } from 'react';
import ThankYou from '../components/TableOrder/thankYou';

export default function TableOrder() {
  const [page, setPage] = useState(1);
  return (
    <>
    <Navbar setPage={setPage}/>
    {page === 1 ? <ReserveTable setPage={setPage} /> : null}
    {page === 2 ? <ThankYou /> : null}
    <Footer/>
    </>
  )
}
