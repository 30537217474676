import React, { useState, useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import {SeatPicker} from "../SeatPicker/SeatPicker";
import './Reservetable.css';
import axios from "axios";

import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Button
} from "reactstrap";

import Table from "./table";

const ReserveTable = ({setPage}) => {

  let [pdate, setPdate] = useState(new Date());
  
  const [totalTables, setTotalTables] = useState([]);

  // List of potential locations
  const [locations] = useState(["همه مکان ها", "سالن مرکزی", "سالن شرقی", "سالن غربی","کافه شرقی","کافه غربی"]);
  const [times] = useState([
    "12 تا 14",
    "14 تا 16",
    "16 تا 18",
    "18 تا 20",
    "20 تا 22",
    "22 تا 24"
  ]);

  // User's selections
  const [selection, setSelection] = useState({
    table: {
      name: null,
      id: null
    },
    date: new Date(),
    time: times[0],
    location: "همه مکان ها",
    size: 1
  });

  function handleChange(pdate){
    let newSel = {
      ...selection,
      table: {
        ...selection.table
      },
      date: new Date(pdate)
    };
    setSelection(newSel);
    setPdate(pdate);
  }

  // User's booking details
  const [booking, setBooking] = useState({
    name: "",
    phone: "",
    email: ""
  });

  // tables state

  const [tablesstate,setTablesstate] = useState();

  // Basic reservation "validation"
  const [reservationError, setReservationError] = useState(false);

  const getDate = _ => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const date =
      months[selection.date.getMonth()] +
      " " +
      selection.date.getDate() +
      " " +
      selection.date.getFullYear();
    let time = selection.time.slice(0, -6);
    time = selection.time > 12 ? time + 12 + ":00" : time + ":00";
    const datetime = new Date(date + " " + time);
    return datetime;
  };
  const getEmptyTables = _ => {
    let tables = totalTables.filter(table => table.isAvailable);
    return tables.length;
  };

  useEffect(() => {
    // Check availability of tables from DB when a date and time is selected
    if (selection.time && selection.date) {
      (async _ => {
        let datetime = getDate();
        console.log(datetime);
        let res = await fetch("http://localhost:3005/availability", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            date: datetime
          })
        });
        res = await res.json();
        await setTablesstate(res);

        // Filter available tables with location and group size criteria
        let tables = res.tables.filter(
          table =>
            (selection.size > 0 ? table.capacity >= selection.size : true) &&
            (selection.location !== "همه مکان ها"
              ? table.location === selection.location
              : true)
        );
        setTotalTables(tables);
      })();
    }
    // eslint-disable-next-line
    // react-hooks/exhaustive-deps
  }, [selection.time, selection.date, selection.size, selection.location]);

  // Make the reservation if all details are filled out
  const reserve = async _ => {
    if (
      (booking.name.length === 0) |
      (booking.phone.length === 0) |
      (booking.email.length === 0)
    ) {
      console.log("Incomplete Details");
      setReservationError(true);
    } else {
      let datetime = getDate();
      const resdata = {
        ...booking,
        date: datetime,
        table : selection.table.id,
        location: selection.location
      }
      console.log(resdata);
        await axios.post("http://localhost:3005/reserve",resdata)
      // let res = await fetch("http://localhost:3005/reserve", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json"
      //   },
      //   body: JSON.stringify({
      //     ...booking,
      //     date: datetime,
      //     table: selection.table.id
      //   })
      // });
      // res = await res.text();
      console.log("Reserved");
      setPage(2);
    }
  };

  // Generate party size dropdown
  const getSizes = _ => {
    let newSizes = [];

    for (let i = 1; i < 8; i++) {
      newSizes.push(
        <DropdownItem
          key={i}
          className="booking-dropdown-item"
          onClick={e => {
            let newSel = {
              ...selection,
              table: {
                ...selection.table
              },
              size: i
            };
            setSelection(newSel);
          }}
        >
          {i}
        </DropdownItem>
      );
    }
    return newSizes;
  };

  // Generate locations dropdown
  const getLocations = _ => {
    let newLocations = [];
    locations.forEach(loc => {
      newLocations.push(
        <DropdownItem
          key={loc}
          className="booking-dropdown-item"
          onClick={_ => {
            let newSel = {
              ...selection,
              table: {
                ...selection.table
              },
              location: loc
            };
            setSelection(newSel);
          }}
        >
          {loc}
        </DropdownItem>
      );
    });
    return newLocations;
  };

  // Generate locations dropdown
  const getTimes = _ => {
    let newTimes = [];
    times.forEach(time => {
      newTimes.push(
        <DropdownItem
          key={time}
          className="booking-dropdown-item"
          onClick={_ => {
            let newSel = {
              ...selection,
              table: {
                ...selection.table
              },
              time: time
            };
            setSelection(newSel);
          }}
        >
          {time}
        </DropdownItem>
      );
    });
    return newTimes;
  };

  // Generating tables from available tables state
  const getTables = _ => {
    console.log("Getting tables");
    if (getEmptyTables() > 0) {
      let tables = [];
      totalTables.forEach(table => {
        if (table.isAvailable) {
          tables.push(
            <Table
              key={table._id}
              id={table._id}
              chairs={table.capacity}
              name={table.name}
              empty
              selectTable={selectTable}
            />
          );
        } else {
          tables.push(
            <Table
              key={table._id}
              id={table._id}
              chairs={table.capacity}
              name={table.name}
              selectTable={selectTable}
            />
          );
        }
      });
      return tables;
    }
  };

  return (
    <div className="container">
      <Row noGutters className="text-center align-items-center pizza-cta">
        <Col>
          <p className="looking-for-pizza">
            {!selection.table.id ? "یک میز رزرو کنید!" : "تایید رزرو میز"}
            <i
              className={
                !selection.table.id
                  ? "fas fa-chair pizza-slice"
                  : "fas fa-clipboard-check pizza-slice"
              }
            ></i>
          </p>
          <p className="selected-table">
            {selection.table.id
              ? "شما در حال رزرو میز شماره " + selection.table.id + " در قسمت " + selection.location + " هستید"
              : null}
          </p>

          {reservationError ? (
            <p className="reservation-error">
              * لطفا همه جزئیات را وارد کنید
            </p>
          ) : null}
        </Col>
      </Row>

      {!selection.table.id ? (
        <div id="reservation-stuff">
          <Row noGutters className="text-center align-items-center">
            <Col xs="12" sm="3">
            <div className="input-title">انتخاب تاریخ</div>
              <div className="booking-dropdown dropdown-toggle btn btn-none">
            <DatePicker
              calendar={persian}
              locale={persian_fa}
              value={pdate}
              onChange={handleChange}
              editable={false}
              />
              </div>
            {/* <input
                type="date"
                required="required"
                className="booking-dropdown"
                value={selection.date.toISOString().split("T")[0]}
                onChange={e => {
                  if (!isNaN(new Date(new Date(e.target.value)))) {
                    let newSel = {
                      ...selection,
                      table: {
                        ...selection.table
                      },
                      date: new Date(e.target.value)
                    };
                    setSelection(newSel);
                  } else {
                    console.log("Invalid date");
                    let newSel = {
                      ...selection,
                      table: {
                        ...selection.table
                      },
                      date: new Date()
                    };
                    setSelection(newSel);
                  }
                }}
              ></input> */}
            </Col>
            <Col xs="12" sm="3">
              <UncontrolledDropdown>
              <div className="input-title" >زمان ورود</div>
                <DropdownToggle color="none" caret className="booking-dropdown">
                  {selection.time === null ? `${times[0]}` : selection.time}
                </DropdownToggle>
                <DropdownMenu right className="booking-dropdown-menu">
                  {getTimes()}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col xs="12" sm="3">
              <UncontrolledDropdown>
              <div className="input-title">مکان نشستن</div>
                <DropdownToggle color="none" caret className="booking-dropdown">
                  {selection.location}
                </DropdownToggle>
                <DropdownMenu right className="booking-dropdown-menu">
                  {getLocations()}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col xs="12" sm="3">
              <UncontrolledDropdown>
              <div className="input-title">تعداد مهمانان</div>
                <DropdownToggle color="none" caret className="booking-dropdown">
                  { selection.size.toString()}
                </DropdownToggle>
                <DropdownMenu right className="booking-dropdown-menu">
                  {getSizes()}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          <Row noGutters className="tables-display">
            <Col>
              {/* {getEmptyTables() > 0 ? (
                <p className="available-tables">{getEmptyTables()} موجود</p>
              ) : null}

              {selection.date && selection.time ? (
                getEmptyTables() > 0 ? (
                  <div>
                    <div className="table-key">
                      <span className="empty-table"></span> &nbsp; موجود
                      &nbsp;&nbsp;
                      <span className="full-table"></span> &nbsp; ناموجود
                      &nbsp;&nbsp;
                    </div>
                    <Row noGutters>{getTables()}</Row>
                  </div>
                ) : (
                  <p className="table-display-message">هیچ میزی موجود نیست!</p>
                )
              ) : (
                <p className="table-display-message">
                لطفا یک تاریخ و زمان برای رزرو انتخاب کنید!
                </p>
              )} */}
             <SeatPicker selection={selection} setSelection={setSelection} tablestate={tablesstate}/>
            </Col>
          </Row>
        </div>
      ) : (
        <div id="confirm-reservation-stuff">
          <Row
            noGutters
            className="text-center justify-content-center reservation-details-container"
          >
            <Col xs="12" sm="3" className="reservation-details">
              <Input
                type="text"
                bsSize="lg"
                placeholder="نام و نام خانوادگی"
                className="reservation-input"
                value={booking.name}
                onChange={e => {
                  setBooking({
                    ...booking,
                    name: e.target.value
                  });
                }}
              />
            </Col>
            <Col xs="12" sm="3" className="reservation-details">
              <Input
                type="text"
                bsSize="lg"
                placeholder="تلفن همراه"
                className="reservation-input"
                value={booking.phone}
                onChange={e => {
                  setBooking({
                    ...booking,
                    phone: e.target.value
                  });
                }}
              />
            </Col>
            <Col xs="12" sm="3" className="reservation-details">
              <Input
                type="text"
                bsSize="lg"
                placeholder="ایمیل"
                className="reservation-input"
                value={booking.email}
                onChange={e => {
                  setBooking({
                    ...booking,
                    email: e.target.value
                  });
                }}
              />
            </Col>
          </Row>
          <Row noGutters className="text-center">
            <Col>
              <Button
                color="none"
                className="book-table-btn"
                onClick={_ => {
                  reserve();
                }}
              >
                رزرو کنید
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ReserveTable;