import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      <img src={images.G} alt="G_overlay" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">درباره ما</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans justify">اولین چیزی که واژه ی اطلس به ذهن ما تداعی می‌کند تصویری با وسعت بی‌کران و سادگی و در عین حال جذابیتی وصف ناشدنی در عین خاص بودن است؛ مجموعه کافه رستوران اطلس همواره تلاش می‌کند با بهره گیری از مواد اولیه با کیفیت در محیطی آرام و صمیمی، تصویری مملو از طعم و مزه‌های خاص و لحظات خاطره‌انگیز را برای میهمانان خود ایجاد کند‌، کافه رستوران اطلس با وجود فضاهای متفاوت می تواند مکانی مناسب برای جلسات، میهمانی ها و دورهمی‌های خاص باشد.</p>
        {/* <button type="button" className="custom__button">Know More</button> */}
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">طعم اصیل غذای عربی</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans justify"> دنیای عرب که بسیار گسترده و وسیع است از مراکش و لیبی و مصر آغاز می شود تا شبه جزیره عربستان و عمان و قطر. ادویه های دنیای عرب نشات گرفته از ادویه ها و سبک اشپزی ایرانی و هندی و اسپانیایی است. ترکیبی خوش عطر و خوش بو متناسب با آب و هوا و سبک و سیاق این منطقه. آنچه مسلم است ادویه های دنیای عرب نشان دهنده تجارت و قدمت این منطقه است.</p>
        {/* <button type="button" className="custom__button">Know More</button> */}
      </div>
    </div>
  </div>
);

export default AboutUs;
