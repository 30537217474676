import React from "react";
import { Row, Col } from "reactstrap";

export default function ThankYou () {
  return (
    <div>
      <Row noGutters className="text-center">
        <Col>
          <p className="thanks-header">با تشکر</p>
          <i className="fas fa-pizza-slice thank-you-pizza"></i>
          <p className="thanks-subtext">
            رزرو شما با موفقیت ثبت شد
          </p>
        </Col>
      </Row>
    </div>
  );
};
