import React, { useState } from 'react';
import './adminpanel.css';
import ManageReserves from './ManageReserves';
import ShowReserves from './ShowReserves';

export const AdminPanel = () => {
  const [show,setShow]= useState(1)
  return (
    <>
    <div className="admincontainer">
      <div className="sidebar">
       <div className="sidebar-title">پنل مدیر رستوران</div>
       <div className="sidebarbtn" onClick={()=>setShow(1)}>نمایش رزروها</div>
       <div className="sidebarbtn" onClick={()=>setShow(2)} >مدیریت رزرو ها</div>
      </div>
      <div className="main">
      {show==1 ? <ShowReserves/>: show==2? <ManageReserves/> : null}
      </div>
    </div>
    </>
  )
}
