import React from 'react'
import { Navigate } from 'react-router-dom';

export default function ProtectedRoute({children}) {
  const token = localStorage.getItem('token');
  if (!token){
  return  <Navigate to="/login" state={{ from: history.location }} />
  }
  return  children;
}
