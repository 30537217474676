import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="میزبانی شما افتخار ماست" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>تماس با ما</h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">زعفرانیه، خیابان مقدس اردبیلی، بین خیابان کیهان و پسیان </p>
        <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>ساعات کاری</p>
        <p className="p__opensans">هفت روز هفته</p>
        <p className="p__opensans">ساعت 12 الی 24</p>
      </div>
      {/* <button type="button" className="custom__button" style={{ marginTop: '2rem' }}>Visit Us</button> */}
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="finus_img" />
    </div>
  </div>
);

export default FindUs;
