import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="کافه رستوران اطلس" />
      <h1 className="headtext__cormorant">ادویه ها برای طعم های عربی</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image"/>
          <p className="p__opensans">غذای تازه، ایده های تازه</p>
        </div>
        <p className="p__opensans justify">بد نیست بدانید در کتاب های آشپزی دنیای عرب را به سه منطقه مغرب ، هلال بارور و شبه جزیره عربستان تقسیم میکنند که هر سه منطقه شباهت ها و تفاوت های جالبی دارند. این ویژگی ها تحت تأثیر آب و هوا ، امکان پرورش گیاهان و همچنین تجارت در هر منطقه است. عرب ها نیز مانند ایرانیان بسیار مهمان نواز هستند و زمان که مهمان دارند تلاش بسیاری جهت رفاه حال او میکنند. وعده اصلی در جهان عرب ، وعده شام است که با سخاوت تمام از گوشت گوسفند، قهوه و چای عربی در یک بشقاب بزرگ و مشترک تشکیل می شود.</p>
      </div>

      {/* <div className="app__chef-sign">
        <p>Kevin Luo</p>
        <p className="p__opensans">Chef & Founder</p>
        <img src={images.sign} alt="sign_image" />
      </div> */}
    </div>
  </div>
);

export default Chef;
