import React from 'react'
import './seatpicker.css'
import { images } from '../../constants';

export const SeatPicker = ({selection,setSelection,tablestate}) => {

  const handleplace = (place) =>{
      let newSel = {
        ...selection,
        table: {
          ...selection.table
        },
        location: place
      };
      setSelection(newSel);
    }

  // Clicking on a table sets the selection state
  const selectTable = (table_id) => {
    setSelection({
      ...selection,
      table: {
        ...selection.table,
        id: table_id
      }
    });
  };

  const availibility = (name) =>{
  const availibility = tablestate.tables.find(item => item.name == name);
  const imgs = images;
  const red= "-red";
  const tableimg = eval(`imgs.p${availibility.capacity}`);
  const tableimgres = eval(`imgs.p${availibility.capacity}red`);
    if (availibility.isAvailable){return(<img className={'table'+name} src={tableimg} onClick={()=>selectTable(name)}></img>)}
   return(<img className={'table'+name+' reserved'} src={tableimgres}></img>);
  }

  return (<>
{selection.location === "همه مکان ها" && tablestate ?
<div className='mapContainer'>
<div className='mainzone'>
<img className='mainzoneimg' src={images.mainzone} onClick={()=>handleplace('سالن مرکزی')} alt="" />
</div>
<div className='saloncontainer'>
<div className='rightsalon'>  <img className='rightsalonimg' src={images.rightsalon} onClick={()=>handleplace('سالن شرقی')} alt="" /></div>
<div className='leftsalon'>  <img className='leftsalonimg' src={images.leftsalon} onClick={()=>handleplace('سالن غربی')} alt="" /></div>
</div>
<div className="cafecontainer">
<div className='leftcafe'>  <img className='leftcafeimg' src={images.leftcafe} onClick={()=>handleplace('کافه شرقی')} alt="" /></div>
<div className='rightcafe'>  <img className='rightcafeimg' src={images.rightcafe} onClick={()=>handleplace('کافه غربی')} alt="" /></div>
</div>
</div>
: selection.location === "سالن مرکزی" && tablestate?
<div className='mainzone relpos'>
  <img className='mainzoneimg lowopacity' src={images.mainzone} alt="" />
  {availibility(1)}
  {availibility(2)}
  {availibility(3)}
  {availibility(4)}
  {availibility(5)}
  {availibility(6)}
  {availibility(7)}
  {availibility(8)}
  {availibility(9)}
  {availibility(10)}
  {availibility(11)}
</div>
:selection.location === "سالن غربی" && tablestate?
<div className='wtrascontainer'>
<div className=''>  
<img className='wsalonimg lowopacity' src={images.leftsalon} alt="" />
{availibility(12)}
{availibility(13)}
{availibility(14)}
{availibility(15)}
{availibility(16)}
{availibility(17)}
<img className='table17' src={images.p4}></img>
</div>
</div>
:selection.location === "سالن شرقی" && tablestate?
<div className='wtrascontainer'>
<div className=''>  
<img className='wsalonimg lowopacity' src={images.rightsalon} alt="" />
<img className='table18' src={images.p4}></img>
<img className='table19' src={images.p4}></img>
<img className='table20' src={images.p4}></img>
<img className='table21' src={images.p4}></img> 
<img className='table22' src={images.p6}></img>
<img className='table23' src={images.p6}></img>
<img className='table24' src={images.p4}></img>
</div>
</div>
:selection.location === "کافه غربی" && tablestate?
<div className='wtrascontainer'>
<div className=''>  
<img className='wsalonimg lowopacity' src={images.rightcafe} alt="" />
<img className='table25' src={images.p4}></img>
<img className='table26' src={images.p2}></img>
<img className='table27' src={images.p4}></img>
<img className='table28' src={images.p3}></img> 
<img className='table29' src={images.p4}></img>
<img className='table30' src={images.p3}></img>
<img className='table31' src={images.p6}></img>
</div>
</div>
:selection.location === "کافه شرقی" && tablestate?
<div className='wtrascontainer'>
<div className=''>  
<img className='wsalonimg lowopacity' src={images.leftcafe} alt="" />
<img className='table32' src={images.p2}></img>
<img className='table33' src={images.p2}></img>
<img className='table34' src={images.p2}></img>
<img className='table35' src={images.p2}></img> 
<img className='table36' src={images.p5}></img>
<img className='table37' src={images.p5}></img>
<img className='table38' src={images.p4}></img>
<img className='table39' src={images.p4}></img>
</div>
</div>
:<div className='server-error'>مشکل برقراری ارتباط با سرور!</div>
}
  </>)
}
