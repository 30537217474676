import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
  
    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">تماس با ما</h1>
        <p className="p__opensans">زعفرانیه، خیابان مقدس اردبیلی، بین خیابان کیهان و پسیان </p>
        <p className="p__opensans">021-22400600</p>
        <p className="p__opensans">09385053000</p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.gericht} alt="footer_logo" />
        {/* <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p> */}
        {/* <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} /> */}
        <div className="app__footer-links_icons">
         <a href='https://instagram.com/atlas.loungerest'> <FiInstagram /></a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">ساعات کاری</h1>
        <p className="p__opensans">هفت روز هفته</p>
        <p className="p__opensans">12 الی 24</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">تمامی حقوق برای گروه اطلس محفوظ است</p>
    </div>

  </div>
);

export default Footer;
