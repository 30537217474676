import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import {AiOutlineSchedule} from 'react-icons/ai';
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
       <a href='/'> <img src={images.gericht} alt="app__logo" /></a>
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans"><a href="/#home">خانه</a></li>
        <li className="p__opensans"><a href="/#about">درباره ما</a></li>
        <li className="p__opensans"><a href="/#menu">منو</a></li>
        <li className="p__opensans"><a href="/#gallery">گالری</a></li>
        <li className="p__opensans"><a href="/#contact">تماس با ما</a></li>
      </ul>
      {/* <div className="app__navbar-login">
        <a href="#login" className="p__opensans">ورود / ثبت نام</a>
        <div />
        <a href="/" className="p__opensans">رزرو میز</a>
      </div> */}
      
      {/* <a href="/reserve" className="p__opensans reservelink"><AiOutlineSchedule size="2em" color="var(--color-golden)"/>رزرو میز</a> */}
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="#home" onClick={() => setToggleMenu(false)}>خانه</a></li>
              <li><a href="#about" onClick={() => setToggleMenu(false)}>درباره ما</a></li>
              <li><a href="#menu" onClick={() => setToggleMenu(false)}>منو</a></li>
              <li><a href="#gallery" onClick={() => setToggleMenu(false)}>گالری</a></li>
              <li><a href="#contact" onClick={() => setToggleMenu(false)}>تماس با ما</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
