import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import gallery05 from '../assets/gallery05.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import mainzone from '../assets/mainzone.png';
import leftsalon from '../assets/wsalon.png';
import rightsalon from '../assets/esalon.png';
import rightcafe from '../assets/wcafe.png';
import leftcafe from '../assets/ecafe.png';
import p2 from '../assets/2p.png';
import p3 from '../assets/3p.png';
import p4 from '../assets/4p.png';
import p6 from '../assets/6p.png';
import p8 from '../assets/8p.png';
import p5 from '../assets/5p.png';
import p2red from '../assets/2p-red.png';
import p3red from '../assets/3p-red.png';
import p4red from '../assets/4p-red.png';
import p6red from '../assets/6p-red.png';
import p8red from '../assets/8p-red.png';
import p5red from '../assets/5p-red.png';

export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  mainzone,
  leftsalon,
  rightsalon,
  rightcafe,
  leftcafe,
  p2,p3,p4,p6,p8,p5,
  p2red,p3red,p4red,p6red,p8red,p5red,

};
