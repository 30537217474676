import React from 'react';
import { AboutUs, Chef, FindUs, Footer, Gallery, Header, Laurels, SpecialMenu } from '../container';
import { Navbar } from '../components';

const Home = () => (
  <div>
    <Navbar />
    <Header />
    <AboutUs />
    {/* <SpecialMenu /> */}
    <Chef />
    <Gallery />
    <FindUs />
    <Footer />
  </div>
);

export default Home;
