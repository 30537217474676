import React from 'react';
import { Footer } from '../container';
import { Navbar } from '../components';
import Login from '../components/Login/Login';

const Admin = () => (
  <div>
    <Navbar />
    <Login />
    <Footer />
  </div>
);

export default Admin;
