import React from 'react';
import Intro from '../Intro/Intro';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (<>
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="اطلس، اقیانوسی از طعم و مزه های معطر" />
      <h1 className="app__header-h1">کافه رستوران اطلس</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>مکانی که در آن غذا، طراحی و محیط گرد هم می آیند تا تجربه ای به یاد ماندنی را خلق کنند.</p>
      <a href='http://atlas.menusaz.com'> <button type="button" className="custom__button" >نمایش منو</button> </a>
    </div>

      {/* <div className="app__wrapper_img">
      <img className='welcome' src={images.welcome} alt="header_img" />
    </div>  */}
  </div>
  <Intro/>
  </>
);

export default Header;
